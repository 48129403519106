<template>
    <div class="progress-bar" ref="progressBar" @click="progressClick">
    <div class="progress-bar__box">
        <div class="progress-bar__progress" ref="progress"></div>
        <div class="progress-bar__btn-box" ref="progressBtn"
            @touchstart.prevent="onTouchStart"
            @touchmove.prevent="onTouchMove"
            @touchend.prevent="onTouchEnd">
            <div class="progress-bar__btn" style="width:10px;height:10px"></div>
        </div>
    </div>
</div>

</template>

<script>
import {prefixStyle} from '@/utils/dom'

const transform = prefixStyle('transform')
const progressBtnWidth = 4
export default {
    props: {
        percent: {
            type: Number,
            default: 0.1
        }
    },
    watch: {
        percent(val) {
            const barWidth = this.$refs.progressBar.clientWidth - progressBtnWidth
            const offsetWidth = val * barWidth
            this.updateProgress(offsetWidth)
        }
    },
    methods: {
        updateProgress(offsetWidth) {
            // console.log('updateProgress', offsetWidth)
            this.$refs.progress.style.width = `${offsetWidth}px`
            this.$refs.progressBtn.style[transform] = `translate3d(${offsetWidth}px,0,0)`
        },
        progressClick(event) {
            const rect = this.$refs.progressBar.getBoundingClientRect()
            const offsetWidth = event.pageX - rect.left
            this.updateProgress(offsetWidth)

            this.triggerPercent()
        },
        onTouchStart(event) {
            this.dragStatus = 'start';
            this.startX = event.touches[0].clientX
            this.startValue = this.$refs.progress.clientWidth
        },
        onTouchMove(event) {
            if (!this.dragStatus === 'start') {
                return
            }

            this.dragStatus = 'draging'
            const deltaX = event.touches[0].pageX - this.startX
            const offsetWidth = Math.min(this.$refs.progressBar.clientWidth - progressBtnWidth, Math.max(0, this.startValue + deltaX))
            this.updateProgress(offsetWidth)
        },
        onTouchEnd() {
            if (this.dragStatus === 'draging') {
                this.dragStatus = 'end'
                this.triggerPercent()
            }
        },
        triggerPercent() {
            const barWidth = this.$refs.progressBar.clientWidth - progressBtnWidth
            const percent = this.$refs.progress.clientWidth / barWidth
            this.$emit('percentChange', {percent})
        },
    }
}
</script>

<style scoped>
.progress-bar {
    height: 14px;
    display: flex;
    justify-content: center;
}

.progress-bar__box {
    position: relative;
    top: 6px;
    height: 2px;
    width: 100%;
    background-color: #e9e9e9;
    border-radius: 2px;
}

.progress-bar__progress {
    position: absolute;
    height: 100%;
    background-image: linear-gradient(#f9d441, #f9d441),
    linear-gradient(#f9d441, #f9d441);
    background-blend-mode: normal, normal;
    border-radius: 2px;
}

.progress-bar__btn-box {
    position: absolute;
    left: -20px;
    top: -20px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-bar__btn {
    margin-top: 1px;
    position: relative;
    box-sizing: border-box;
    border-radius: 50%;
    background-image: linear-gradient(#f9d441, #f9d441),
    linear-gradient(#f9d441, #f9d441);
    background-blend-mode: normal, normal;
    box-shadow: 0px 2px 6px 0px #f9d441;
    border: solid 1px #ffffff;
}
</style>