import { getWxSign } from '@/api/login'
import to from '@/utils/to'

const wxShareMixin = {
    methods: {
        async getWS(shareCfg) {
            let [err, res] = await to(getWxSign({ url: location.href}))
            if (err) return
            
            /* eslint-disable */
            res.jsApiList = ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage']
            wx.config(res)
            
            wx.ready(() => {
                wx.updateAppMessageShareData(shareCfg)
                wx.updateTimelineShareData(shareCfg)
                wx.onMenuShareAppMessage(shareCfg)
            })
            wx.error(res => {
                console.log(res)
            })
            /* eslint-enable*/
        },
    },
}

export default wxShareMixin