<template>
  <div class="container">
    <div class="progress">
      <progress-bar
        :percent="percent"
        @percentChange="onDragChange"
      ></progress-bar>
      <div class="player-percent">
        <span>{{ curShowtime }}</span>
        <span>{{ totalShowTime }}</span>
      </div>
    </div>

    <div class="player-btns">
      <img
        class="prevBtn"
        src="@/assets/imgs/player/prev.png"
        @click="onPrev"
      />
      <div class="play">
        <img
          class="play__img"
          :src="status !== 'playing' ? playIcon : pauseIcon"
          @click="onPlay"
        />
        <div v-if="loading" class="play__loading">
          <van-loading type="spinner" />
        </div>
      </div>
      <img
        class="nextBtn"
        src="@/assets/imgs/player/next.png"
        @click="onNext"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import ProgressBar from "./ProgressBar";
import { Image, Loading } from "vant";

export default {
  components: {
    ProgressBar,
    [Image.name]: Image,
    [Loading.name]: Loading,
  },
  data() {
    return {
      playIcon: require("@/assets/imgs/player/play.png"),
      pauseIcon: require("@/assets/imgs/player/stop.png"),
    };
  },
  computed: {
    ...mapState("audio", ["curIdx", "playList", "loading"]),
    ...mapGetters("audio", [
      "status",
      "percent",
      "curShowtime",
      "totalShowTime",
    ]),
  },
  watch: {
    status(val) {
      if (val === "end") {
        this.onNext();
      }
    },
  },
  methods: {
    onDragChange(evt) {
      this.setPercent(evt.percent);
    },
    onPlay() {
      const status = this.status === "playing" ? "pause" : "playing";
      this.setStatus(status);
    },
    onPrev() {
      const idx = this.curIdx - 1;
      if (idx < 0) {
        this.$toast("已经是第一支音频了");
        return;
      }
      this.play(idx);
      this.$emit("change", this.playList[idx]);
    },
    onNext() {
      const idx = this.curIdx + 1;
      if (idx >= this.playList.length) {
        this.$toast("已经是最后一支音频了");
        return;
      }
      this.play(idx);
      this.$emit("change", this.playList[idx]);
    },
    ...mapMutations("audio", ["play", "setStatus", "setPercent"]),
  },
};
</script>

<style scoped>
.container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.topic {
  font-weight: 700;
  font-size: 18px;
  color: #333;
}

.topic-img {
  margin-top: 4px;
  width: 174px;
  height: 114px;
  box-shadow: 0px 8px 10px 0px #ccc;
  border-radius: 4px;
}

.progress {
  margin: 14px 0 0 0;
  width: 300px;
}

.player-percent {
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.player-btns {
  color: #666;
  display: flex;
  align-items: center;
}

.prevBtn {
  width: 20px;
  height: 20px;
}

.nextBtn {
  width: 20px;
  height: 20px;
}

.play {
  width: 48px;
  height: 48px;
  margin: 0 24px;
  position: relative;
}

.play__img {
  width: 100%;
  height: 100%;
}

.play__loading {
  width: 48px;
  height: 48px;
  background-color: #000;
  opacity: 0.2;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>