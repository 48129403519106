<template>
  <div class="comments-box">
    <div v-for="(item, idx) in list" :key="item.id">
      <div v-bind:class="['comments-box__comment', idx < (list.length - 1) ? 'van-hairline--bottom': '']">
        <img class="comment__avatar " :src="item.avatar"/>
        <div class="comment__right">
          <div class="comment__header">
            <div>
              <span>{{item.publisher}}</span>
              <span v-if="item.isTop" class="comment__top">置顶</span>
            </div>
            <div @click="onLikeClick(idx, item)" class="comment__like-box">
              <span>{{item.likeNum}}</span>
              <img class="comment__like"
                   :src="item.isLike ? solidLikeIcon : likeIcon"/>
            </div>
          </div>

          <div class="coment__content">
            {{item.content}}
          </div>

          <div v-if="item.reply" class="comment__reply">
            <div class="comment__replyer">作者回复:</div>
            <div>{{item.reply}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      solidLikeIcon: require('@/assets/imgs/article/zan2.png'),
      likeIcon: require('@/assets/imgs/article/zan1.png')
    }
  },
  methods: {
    onLikeClick(idx, item) {
      let res = {
        id: item.id,
        idx: idx,
        isLike: !item.isLike
      }
      this.$emit('like', res)
    }
  }
}
</script>

<style scoped>

.comments-box__comment {
  display: flex;
  align-items: top;
  padding-top: 14px;
  padding-bottom: 20px;
}

.comment__avatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.comment__right {
  flex: 1;
  margin-left: 9px;
}

.comment__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
}

.comment__top {
  color: #fff;
  font-size: 10px;
  background: #ccc;
  border-radius: 2px;
  padding: 1px 4px;
  margin-left: 10px;
}

.comment__like-box {
  color: #5a719b;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.comment__like {
  width: 14px;
  height: 14px;
  margin-left: 4px;
}

.coment__content {
  font-size: 12px;
  margin-top: 10px;
}

.comment__reply {
  margin-top: 12px;
  font-size: 12px;
  padding: 12px 6px;
  background: #f4f4f4;
}

.comment__replyer {
  color: #5a719b;
  font-size: 12px;
  margin-bottom: 2px;
}
</style>