<template>
  <div>
    <div class="page-top">
      <img class="article-cover" :src="detail.coverUrl" />
      <div class="title">{{ detail.title }}</div>
      <div class="columnist-box">
        {{detail.albumName}}
        <div v-if="!detail.albumFav" class="collect" @click="onAlbumFavoritesClick(detail.albumId)">
          <img src="@/assets/imgs/article/add.png">收藏该专栏
        </div>

        <div v-else class="collect-icon" @click="onAlbumFavoritesClick(detail.albumId)">
          已收藏
        </div>
      </div>
      <audio-player @change="onAudioChange"></audio-player>
    </div>
    <div class="page-center">
      <div class="columnist-center__box">
        <div class="columnist-box__left">
          <img class="columnist-box__cover" :src="detail.albumCover" />
          <div>
            <div class="columnist-box__title">{{ detail.albumName }}</div>
            <div>已更新{{ detail.articleNum }}篇文章</div>
          </div>
        </div>

        <div class="columnist-box__btn" @click="onAlbumTap(detail.albumId)">
          <div>收听更多</div>
          <van-icon name="arrow" size="12"></van-icon>
        </div>
      </div>
      <div class="article-box__content" v-html="detail.content"></div>
      <div class="article-box__copyright">
        文章版权归海邀科技所有，转载请注明出处
      </div>
      <div class="article-box__view">
      <div>阅读量：{{detail.readingNum}}</div>
      <div>发布时间：{{detail.createTime}}</div>
      </div>
      <div class="reply" v-if="commentList.length > 0">
        <div class="reply__title">精选留言</div>
        <comment :list="commentList" @like="onToggleLike"></comment>
      </div>
    </div>

    <div class="page-bottom">
      <div @click="onFavoritesClick">
        <img class="icon-box"
             :src="detail.isFavorite ? solidFavoritesIcon : favoritesIcon">
        <span>收藏</span>
      </div>
      <div @click="onCommentClick">
        <img class="icon-box" src="@/assets/imgs/article/comment.png" />
        <span>评论</span>
      </div>
      <div @click="onLikeClick">
        <img class="icon-box" :src="detail.isLike ? solidLikeIcon : likeIcon" />
        <span>点赞</span>
      </div>
    </div>
    <img
      class="home-btn"
      src="@/assets/imgs/article/home.png"
      @click="onIndex"
    />
  </div>
</template>

<script>
import AudioPlayer from "@/components/AudioPlayer.vue";
import Comment from "./Comment";
import { getArticleDetail, getArticleComments } from "@/api/album";
import { toggleLike, toggleFavorites, articleLike } from "@/api/mine";
import { mapMutations } from "vuex";
import { Icon } from "vant";
import wxShareMixin from "@/mixins/wx_share_mixin";

export default {
  components: {
    AudioPlayer,
    Comment,
    [Icon.name]: Icon,
  },
  mixins: [wxShareMixin],
  data() {
    return {
      id: "",
      solidFavoritesIcon: require("@/assets/imgs/article/star2.png"),
      favoritesIcon: require("@/assets/imgs/article/star1.png"),
      solidLikeIcon: require("@/assets/imgs/article/zan2.png"),
      likeIcon: require("@/assets/imgs/article/zan1.png"),
      detail: {},
      commentList: [],
      playList: [],
      curIdx: -1, // 本文章再专辑列表中的位置
      from: "", // 从哪个地方跳转来的，abar 表示是从首页播放条进来的，此时不能重头播放
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    onAudioChange(val) {
      this.id = val.id;
      this.getData();
    },
    getData(withList = true) {
      this.$showLoading();

      Promise.all([
        getArticleDetail(this.id, { withList }),
        getArticleComments(this.id),
      ])
        .then((res) => {
          if (withList) {
            this.mapPlayList(res[0].playList);
          }
          this.detail = res[0];
          this.commentList = res[1];

          const shareCfg = {
            title: this.detail.title,
            desc: this.detail.digest,
            link: location.href,
            imgUrl: this.detail.albumThumbnail,
          };
          this.getWS(shareCfg);
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    mapPlayList(list) {
      // 寻找本文章在播放列表的哪个下标
      list.some((item, idx) => {
        if (item.id === this.id) {
          this.curIdx = idx;
          return true;
        } else {
          return false;
        }
      });

      this.playList = list;

      // 首页过来的，不重头播放
      // console.log(list)
      // console.log(this.curIdx)
      if (this.from != "abar") {
        this.setPlayList(list);
        this.play(this.curIdx);
      }
    },
    onToggleLike(detail) {
      let countNum = this.commentList[detail.idx].likeNum;
      detail.isLike ? ++countNum : --countNum;

      this.commentList[detail.idx].isLike = detail.isLike;
      this.commentList[detail.idx].likeNum = countNum;

      toggleLike(detail.id);
    },
    onFavoritesClick() {
      this.detail["isFavorite"] = !this.detail.isFavorite;
      toggleFavorites(this.id, "article");
    },
    onAlbumFavoritesClick(id) {
      this.detail['albumFav'] = !this.detail.albumFav
      toggleFavorites(id, 'album')
    },
    onCommentClick() {
      this.$router.push({
        name: "CommentPage",
        query: { id: this.id },
      });
    },
    onLikeClick() {
      this.detail["isLike"] = !this.detail.isLike;
      articleLike(this.id);
    },
    onIndex() {
      this.$router.push("/");
    },
    onAlbumTap(id) {
      this.$router.push({
        name: 'Album',
        query: {
          id,
        },
      });
    },
    ...mapMutations("audio", ["setPlayList", "play"]),
  },
};
</script>

<style scoped>
.page-top {
  padding: 16px 30px 0px;
  border-bottom: 8px solid #f3f7fa;
}

.article-cover {
  width: 180px;
  height: 140px;
  border-radius: 8px;
  display: block;
  margin: 0 auto;
}

.title {
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  color: #1a1a1a;
  text-align: center;
  margin-top: 20px;
}

.columnist-box {
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
}

.columnist-box div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 2px 8px;
  margin-left: 8px;
}

.collect {
  border: 1px solid #ffc83e;
  color: #ffc83e;
  font-size: 12px;
  line-height: 17px;
}

.columnist-box img {
  width: 8px;
  height: 8px;
  margin-right: 4px;
}

.page-center {
  padding: 16px;
  padding-bottom: 50px;
}

.columnist-center__box {
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  color: #999;
}

.columnist-box__left {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 17px;
  color: #9a9a9a;
}

.columnist-box__cover {
  width: 70px;
  height: 44px;
  border-radius: 4px;
  margin-right: 12px;
}

.columnist-box__title {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #333333;
}

.columnist-box__btn {
  padding: 4px 8px;
  background-color: #ffc83e;
  font-size: 12px;
  border-radius: 12px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.columnist-box__btn > div {
  height: 16px;
}

.article-box__content {
    width: 100%;
    font-size: 16px;
    color: #5f5f5f;
    margin-bottom: 14px;
    line-height: 2;
    text-align: justify;
    letter-spacing: 1px;
    padding: 0 0 30px 0;
}

.article-box__content >>> img {
    width: 100%;
}


.page-bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-box {
  width: 24px;
  height: 24px;
}

.page-bottom > div {
  padding: 12px 34px;
  display: flex;
  align-items: center;
}

.home-btn {
  position: fixed;
  right: 16px;
  bottom: 100px;
  width: 80px;
  height: 80px;
}

.article-box__content {
  width: 100%;
  font-size: 16px;
  color: #5f5f5f;
  margin-bottom: 14px;
  line-height: 2;
  text-align: justify;
  letter-spacing: 1px;
  padding: 0 0 30px 0;
}

.article-box__content >>> img {
  width: 100%;
}

.article-box__view{
  display: flex;
  justify-content:space-between;
  font-size: 12px;
  color: #999;
  margin-bottom: 10px;
}

.article-box__copyright {
  text-align: center;
  font-size: 12px;
  color: #999;
  margin-bottom: 10px;
}

.reply {
  padding: 24px 0px;
  margin-bottom: 60px;
  border-top: 0.5px solid #e5e5e5;
}

.reply__title {
  font-size: 12px;
  color: #333;
  margin-bottom: 14px;
}

.collect-icon {
  width: 56px;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  font-size: 12px;
  line-height: 17px;
  color: #b3b3b3;
}
</style>

<style>
.ql-align-center {
  text-align: center;
}
</style>