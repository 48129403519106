import { get } from './http.js'

/**
 * 获取文章详情
 */
export const getArticleDetail = (id, params) => get(`/article/v1.0/${id}`, params)

/**
 * 获取专辑列表
 */
export const getAlbumList = () => get(`/article/v1.0/album`)

/**
 * 获取专辑信息
 */
export const getAlbumInfo = (id) => get(`/article/v1.0/album/${id}`)

/**
 * 专辑下的文章列表
 */
export const getAlbumArticleList = (id, sort, pageNum, pageSize) => {
    let params = {
        sort,
        pageNum,
        pageSize
    }

    return get(`/article/v1.0/album/${id}/articles`, params)
}

/**
 * 专辑下的音频列表
 */
export const getAudioList = (id, sort) => {
    let params = {
        sort
    }

    return get(`/article/v1.0/album/${id}/audio`, params)
}

/**
 * 获取文章下的精选留言
 */
export const getArticleComments = (id) => get(`/article/v1.0/${id}/comments`)

/**
 * 生成海报
 */
export const generatePoster = (id, type, link) => {
    let params = {
        type,
        link
    }
    console.log(params)
    return get(`/hxxq/v1.0/poster/${id}`, params)
}